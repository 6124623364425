<template>
  <EChart v-bind="{ option, selectedIndex }" @click:item="handleClickItem" />
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { sumBy } from 'lodash'

import { DefaultLabelFormatterCallbackParams, EChartOption } from 'echarts'

import { CHART_OTHER_TITLE } from './utils/const'
import { BLUR_DIGITAL_VALUES } from '@/const/storage'

import EChart from './EChart.vue'

type Props = {
  data: EChartOption.SeriesPie.DataObject[]
  title?: string

  maxSlices?: number
  total?: number

  colors?: string[]

  valueFormatter?: (data: number) => string
  percentFormatter?: (data: number) => string
}

const props = defineProps<Props>()

const outerSelectedIndex = defineModel<number>('index')
const page = defineModel<number>('page', { default: 0 })

const blurDigitalValues = useLocalStorage(BLUR_DIGITAL_VALUES, false)

const offset = computed(() => page.value * (props.maxSlices || 0))

const otherTotalValue = computed(() => {
  return (
    props.maxSlices &&
    sumBy(props.data.slice(offset.value + props.maxSlices), 'value')
  )
})

const selectedIndex = computed(() =>
  outerSelectedIndex.value !== undefined
    ? outerSelectedIndex.value - offset.value
    : undefined,
)

const data = computed(() => {
  if (!props.maxSlices) return props.data
  const leavingsLength = props.data.length - offset.value
  const length = Math.min(props.maxSlices, leavingsLength)
  return props.data.slice(offset.value, offset.value + length)
})

const seriesPie = computed<EChartOption.SeriesPie>(() => {
  const result = data.value.map((item, index) => {
    const value = Math.abs(item.value || 0)
    const isNegative = item.value && item.value < 0
    return {
      name: item.name,
      value,
      isNegative,
      ...(props.colors
        ? {
            itemStyle: {
              borderColor: props.colors[index],
              color: props.colors[index],
            },
          }
        : {}),
    }
  })

  const leavingsLength = props.data.length - offset.value
  if (props.maxSlices && leavingsLength > props.maxSlices) {
    const isNegative = result.at(-1)?.isNegative
    result.push({
      name: CHART_OTHER_TITLE,
      value: Math.abs(otherTotalValue.value || 0),
      isNegative,
      ...(props.colors
        ? {
            itemStyle: {
              borderColor: `${props.colors.at(-1)}`,
              color: `${props.colors.at(-1)}`,
            },
          }
        : {}),
    })
  }

  return {
    type: 'pie',
    selectedMode: 'single',
    radius: '70%',
    label: {
      formatter: (params: DefaultLabelFormatterCallbackParams) => {
        const { name, percent, value } = params
        return `{name|${name}}\n{value|${blurDigitalValues.value ? '???' : calculatePercent(Number(value), Number(percent))}%}`
      },
    },
    top: props.title || props.total ? '3%' : '0%',
    left: '0%',
    right: '0%',
    bottom: '0%',
    data: result,
  }
})

const option = computed(() => {
  return {
    ...(props.title
      ? {
          title: {
            text: props.title,
            left: 'center',
            textStyle: {
              fontSize: 12,
              fontWeight: 500,
              color: '#BCBCBC',
            } as EChartOption.TextStyleWithRich,
          },
        }
      : {}),
    tooltip: {
      formatter: (
        params: EChartOption.Tooltip.Format | EChartOption.Tooltip.Format[],
      ) => {
        const { color, data, name, value } =
          params as EChartOption.Tooltip.Format
        const sign = data.isNegative ? '-' : ''
        const result = props.valueFormatter?.(Number(value)) || value
        return `<div style="color:${color}">${name}</div><span class="blurable-number">${sign}${result}</span>`
      },
    },
    series: [seriesPie.value],
  }
})

const calculatePercent = (value: number, percent: number) => {
  const result = props.total ? (value / props.total) * 100 : percent
  return props.percentFormatter?.(result) || result
}

const handleClickItem = (index: number, name: string) => {
  if (name === CHART_OTHER_TITLE) {
    page.value++
    outerSelectedIndex.value = undefined
  } else if (index === selectedIndex.value) {
    outerSelectedIndex.value = undefined
  } else {
    outerSelectedIndex.value = offset.value + index
  }
}

watch(
  outerSelectedIndex,
  value => {
    if (value === undefined || !props.maxSlices) return
    const nextPage = (value - (value % props.maxSlices)) / props.maxSlices
    page.value = nextPage
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'UIPieChart',
}
</script>

<style lang="postcss"></style>
