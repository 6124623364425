<template>
  <EChart v-bind="{ option, selectedIndex }" @click:item="handleClickItem" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useDark, useLocalStorage } from '@vueuse/core'

import { EChartOption } from 'echarts'

import { BLUR_DIGITAL_VALUES } from '@/const/storage'

import EChart from './EChart.vue'

type Props = {
  data: [string, number][]

  valueFormatter?: (data: number, fractionDigits?: number) => string
}

const props = defineProps<Props>()

const selectedIndex = defineModel<number>('index')

const isDark = useDark()

const blurDigitalValues = useLocalStorage(BLUR_DIGITAL_VALUES, false)

const negativeColor = computed(() => (isDark.value ? '#F24242' : '#EF4444'))

const category = computed(() => props.data.map(item => item[0]))

const data = computed(() =>
  props.data.map(item =>
    item[1] < 0
      ? {
          value: item[1],
          itemStyle: {
            color: negativeColor.value,
          },
        }
      : item[1],
  ),
)

const option = computed(() => {
  return {
    tooltip: {
      formatter: (
        params: EChartOption.Tooltip.Format | EChartOption.Tooltip.Format[],
      ) => {
        const { color, name, value } = params as EChartOption.Tooltip.Format
        const result = props.valueFormatter?.(Number(value)) || value
        return `<div style="color:${color}">${name}</div><span class="blurable-number">${result}</span>`
      },
    },
    xAxis: {
      data: category.value,
      axisLabel: { interval: 0, rotate: 30 },
    },
    yAxis: {
      axisLabel: {
        formatter: (value: number) =>
          blurDigitalValues.value
            ? '? ??? ???'
            : props.valueFormatter?.(value, 0) || value,
      },
    },
    series: [
      {
        type: 'bar',
        selectedMode: true,
        data: data.value,
      },
    ],
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      containLabel: true,
      height: '97%',
    },
  }
})

const handleClickItem = (index: number) => {
  selectedIndex.value = index
}
</script>

<script lang="ts">
export default {
  name: 'UIBarsChart',
}
</script>

<style lang="postcss"></style>
